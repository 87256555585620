import React from 'react';
import { Typography } from '@mui/material';

const Profile = () => {
  return (
    <>
      <header className="App-header">
        <Typography
          color="primary"
        >
          <h1>Profile</h1>
        </Typography>
      </header>
    </>
  );
};

export default Profile;
