import React from 'react';
import Writers from '../../components/writers/Writers';

const WritersPage: React.FC = () => {
  return (
    <>
      <Writers />
    </>
  );
};

export default WritersPage;
