import React from 'react';

const DashboardAnalytics = () => {
  return (
    <>
      <header className="App-header">
        <h1>DashboardAnalytics</h1>
      </header>
    </>
  );
};

export default DashboardAnalytics;

