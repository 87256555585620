import React from 'react';
import { Typography } from '@mui/material';

const Logout = () => {
  return (
    <>
      <header className="App-header">
        <Typography
          color="primary"
        >
          <h1>Logout</h1>
        </Typography>
      </header>
    </>
  );
};

export default Logout;
