export const commingSoonText = {
  padding: '16px',
  '@media only screen and (max-width: 600px)': {
    fontSize: '20px',
  },
};

export default [
  commingSoonText,
];
